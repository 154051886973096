import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faTiktok } from '@fortawesome/free-brands-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

export default class Social extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hide: true
        };
    }

    componentDidMount() {
        this.setState({ hide: false });
    }

    render() {
        return (
            <div className={`o-child-spacer u-flex-grow c-social c-social--${this.props.type} ${this.state.hide ? 'u-hide' : ''}`}>
                <Link target="_blank" rel="noreferrer" className={`c-social__link c-social__link--${this.props.type}`} to="https://www.facebook.com/Uninterrupted/">
                    <FontAwesomeIcon icon={faFacebook} />
                </Link>
                <Link target="_blank" rel="noreferrer" className={`c-social__link c-social__link--${this.props.type}`} to="https://twitter.com/uninterrupted">
                    <FontAwesomeIcon icon={faTwitter} />
                </Link>
                <Link target="_blank" rel="noreferrer" className={`c-social__link c-social__link--${this.props.type}`} to="https://www.instagram.com/uninterruptedcanada">
                    <FontAwesomeIcon icon={faInstagram} />
                </Link>
                <Link target="_blank" rel="noreferrer" className={`c-social__link c-social__link--${this.props.type}`} to="https://www.youtube.com/channel/UCh0_oEIReJmE7g3ZYVxxthA">
                    <FontAwesomeIcon icon={faYoutube} />
                </Link>
                <Link target="_blank" rel="noreferrer" className={`c-social__link c-social__link--${this.props.type}`} to="https://www.tiktok.com/@uninterruptedcanada">
                    <FontAwesomeIcon icon={faTiktok} />
                </Link>
                <Link target="_blank" rel="noreferrer" className={`c-social__link c-social__link--${this.props.type}`} to="https://ca.linkedin.com/company/uninterruptedca">
                    <FontAwesomeIcon icon={faLinkedinIn} />
                </Link>
            </div>
        );
    }
};